<template>
    <div id="m-app">
        <!-- <button @click="openModal">Open Modal</button> -->

        <div class="zcModal" v-if="isOpen">
            <div :style="cumtomeStyle" class="zcModal-content">
                <span v-if="isCloseBTN" @click="closeModal" class="close">&times;</span>
                <slot name="child"></slot>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "zcModal",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        cumtomeStyle: {
            type: String,
            default: ''
        },
        isCloseBTN: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    methods: {
        openModal() {

            this.$emit("open");
        },
        closeModal() {

            this.$emit("close");
        },
    },
};
</script>
  
<style>
/* CSS for the modal */
.zcModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* display: flex;
width: 25.5rem;
padding: 1.875rem;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 1.875rem; */

.zcModal-content {
    background-color: #fff;
    padding: .875rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 22.5rem;
    line-height: 3rem;
}

@media screen and (max-width:360px) {
    .zcModal {
        padding: 0rem 1rem;
    }

    .zcModal-content {
        width: auto;
    }
}
</style>
  
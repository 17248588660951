<template>
    <div class="w-100">
        <Header />

        <b-row class="mt-6 mb-3 w-100">
            <b-col cols="4" class="zc-text-format px-4">
                <!-- Back Button @start-->
                <div @click="$router.back()" style="width: fit-content;align-items: center;" class="go-back">
                    <img src="@/assets/zaajira-candidate/assets/icons/arrow-left.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <span style="font-size: clamp(1.125rem, 3vw, 1.25rem); text-wrap: nowrap;" class="pl-3">Apply
                        Job</span>
                </div>
                <!-- Back Button @end-->
            </b-col>
            <b-col cols="12" lg="8">
                <div class="pl-3">
                    <div class="p-3">
                        <h2 class="vi-job">
                            {{ job_title }}
                        </h2>
                        <p style="font-size: 1rem;font-weight: 400;" class="generic-typography">
                            {{ currency_code ? currency_code : '' }}
                            {{ job_salary_range_from ? job_salary_range_from :
                                ''
                            }} -
                            {{ job_salary_range_to ? job_salary_range_to : ''
                            }}/Month
                        </p>
                    </div>

                    <div class="zc-card p-3">
                        {{ QuestionAttempts(currentIndex) }}
                        <div>
                            <b-row style="height: 4rem;position: relative;border-bottom: 2px solid #0000001c;"
                                class="d-flex flex-column">
                                <div class="d-flex justify-content-between px-3">
                                    <h2 class="generic-typography q-font">Questions
                                    </h2>
                                    <h2 class="generic-typography">
                                        {{ currentIndex + 1 }}/{{ questions.length }}</h2>
                                </div>
                                <div style="top:0 ;left: 0;" class="Create-vc px-3 w-100">
                                    <div style=" border-radius: 1.875rem;opacity: 0.1;background: #F36F21;"
                                        class="Create-vc-ProgressBar"></div>
                                    <div style="top: 0;" class="active-bar" :style="questions[currentIndex]?.style">
                                    </div>
                                </div>
                            </b-row>
                        </div>
                        <p class="question mt-3">{{ questions[currentIndex].question }}</p>
                        <p @click="selectedOption(op)" v-for="(op, i) in questions[currentIndex].option" :key="i"
                            style="cursor: pointer;" class="option"
                            :class="{ 'answered': op.question_option_id === answeredQue[currentIndex]?.answer }">
                            {{ questions[currentIndex].option[i].question_option_title }}</p>
                        <div v-if="error" class="d-flex flex-row align-items-center">
                            <img src="@/assets/zaajira-candidate/assets/icons/note.svg" width="19px" height="19px"
                                class="img-fluid" />
                            <p class="iv-note">Tap the answers/options you wish to choose</p>
                        </div>
                        <div class="w-100  mb-2 d-flex"
                            :class="currentIndex !== 0 ? 'justify-content-between' : 'justify-content-end'">
                            <span v-if="currentIndex !== 0" style="font-size: 0.875rem; font-weight: 700;"
                                @click="goBack" class="go-back">Back</span>
                            <b-button style="width: 8.5rem;" @click.prevent.stop="AnswerController" class="btn btn-text"
                                variant="primary" size="sm">
                                Next
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <zcModal :is-open="isApplied" @open="isApplied = true" @close="isApplied = false">
            <template v-slot:child>
                <div class="">
                    <h2 class="zc-modal-header">Thank you</h2>
                    <p style="font-size: 1rem; font-weight: 600;" class="zc-modal-para">Your application has been
                        successfully submitted</p>
                    <p style="font-weight: 400; margin-top: 0.75rem;" class="zc-modal-para">All the best for the next
                        step,
                        and while you wait,
                        check out some more jobs</p>
                    <div class="col-12 mt-4 btn-flex-fd-row-jc-sb">
                        <span @click="backToDashboard" class="go-back">Back to
                            dashboard</span>
                        <b-button id="see-more" @click="backToFindJob" variant="primary" size="sm">See more
                            jobs</b-button>
                    </div>
                </div>
            </template>
        </zcModal>
        <zcModal :is-open="isScoreModal" @open="isScoreModal = true" @close="isScoreModal = false">
            <template v-slot:child>
                <div class="">
                    <h2 class="zc-modal-header">Warning</h2>
                    <p style="font-size: 1rem; font-weight: 600;" class="zc-modal-para">Your application does not have a
                        high match rate for this vacancy, Are you sure you wish to submit your application?</p>
                    <div class="col-12 mt-4 btn-flex-fd-row-jc-sb">
                        <span @click="$router.push({ path: `/candidate/job-deatils/${job_id}`, params: { job_id } })"
                            class="go-back">
                            No
                        </span>
                        <b-button id="see-more" @click="UpdateAnswer" variant="primary" size="sm">Yes</b-button>
                    </div>
                </div>
            </template>
        </zcModal>

    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
import zcModal from '../../../components/Modal/Modal.vue'
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { formattedNumber, convertToTimeSince } from '../Helper/HelperFunctions.js'
import { IMAGEURL } from "@/config";
import avtar from '@/assets/zaajira-candidate/assets/bg-images/avtar-image.png'

export default {
    name: "InterviewQuestions",
    components: { Header, zcModal },
    data() {
        return {
            company_id: null,
            job_id: null,
            job_title: null,
            job_salary_range_from: null,
            job_salary_range_to: null,
            currency_code: null,
            questions: [
                // {
                //     question_id: 1,
                //     question: "What is the best way to identify and qualify new leads in the Tanzanian recruitment market?",
                //     options: [
                //         {
                //             option_id: 1,
                //             option: "Attend industry events and networking functions",
                //         },
                //         {
                //             option_id: 2,
                //             option: "Use online lead generation tools",
                //         },
                //         {
                //             option_id: 3,
                //             option: "Contact businesses that are actively hiring",
                //         },
                //         {
                //             option_id: 4,
                //             option: "All of the above",
                //         }
                //     ]


                // },
                // {
                //     question_id: 2,
                //     question: "Do you have excellent Swahili communication skills?",
                //     options: [
                //         {
                //             option_id: 1,
                //             option: "Yes",
                //         },
                //         {
                //             option_id: 2,
                //             option: "No",
                //         },
                //     ]


                // },
                // {
                //     question_id: 3,
                //     question: "What is one of the key skills required for success as a Business development execution?",
                //     options: [
                //         {
                //             option_id: 1,
                //             option: "Expert in accounting and financial management",
                //         },
                //         {
                //             option_id: 2,
                //             option: "Ability to work exclusively as an individual contributor",
                //         },
                //         {
                //             option_id: 3,
                //             option: "Strong business acumen and strategic thinking",
                //         },
                //         {
                //             option_id: 4,
                //             option: "In-depth knowledge of software programming ",
                //         },
                //     ]


                // },
            ],
            answeredQue: [
                // {
                //     question_id: 1,
                //     option_id: 1,
                // },
            ],
            // AttemptsPersentage: (((1) * 100) / this.questions.length).toFixed(0),
            currentIndex: 0,
            error: false,
            isApplied: false,
            isScoreModal: false,
        }
    },
    beforeMount() {
        const job_id = this.$route.params.job_id;
        //console.log('job_id', job_id);
        this.PreFilledData(job_id)
    },
    methods: {
        QuestionAttempts(q) {
            if (this.questions.length === q + 1) {
                const w = `width:95%;`
                //// console.log(w);
                this.questions[q].style = w
                // return w;
            } else {
                //// console.log(q);
                const w = (((q + 1) * 100) / this.questions.length).toFixed(0)
                this.questions[q].style = `width:${w}%;`
                //// console.log(w);
                // return `width:${w}%;`
            }
        },
        selectedOption(op) {
            this.error = false
            const answer = {
                question_id: this.questions[this.currentIndex].question_id,
                answer: op.question_option_id

            }
            //// console.log("answer", answer);
            // this.answeredQue[this.currentIndex] = answer
            this.$set(this.answeredQue, this.currentIndex, answer);
            //// console.log("this.answeredQue", this.answeredQue);

        },

        goBack() {
            this.error = false
            if (this.currentIndex < this.questions.length && this.currentIndex >= 0) {
                this.currentIndex = this.currentIndex - 1
            }
        },
        AnswerController() {

            if (this.answeredQue[this.currentIndex] === undefined) {
                this.error = true
                return
            }
            else {
                this.error = false
                if (this.currentIndex < this.questions.length - 1) {
                    this.currentIndex = this.currentIndex + 1
                    return
                }
                if (this.currentIndex === this.questions.length - 1) {
                    //console.log(this.answeredQue);
                    this.getScore()

                }

            }

        },
        backToDashboard() {
            this.isApplied = false
            this.$router.push({ path: '/candidate/user-profile' })

        },
        backToFindJob() {
            this.isApplied = false
            this.$router.push({ path: '/candidate/home' })

        },

        async PreFilledData(job_id) {
            // this.goBack()
            // await this.GetMasterData()
            const loader = this.$loading.show()
            CandidateService.GetInterviewQuestion(job_id)
                .then((res) => {
                    //console.log("Jobs Detail::res.data", res.data.data);

                    this.company_id = res.data.data[0].company_id
                    this.job_id = res.data.data[0].job_id
                    this.job_title = res.data.data[0].job_title
                    this.currency_code = res.data.data[0].currency_code
                    this.job_salary_range_from = formattedNumber(res.data.data[0].job_salary_range_from),
                        this.job_salary_range_to = formattedNumber(res.data.data[0].job_salary_range_to),
                        this.questions = res.data.data[0].question

                    // const JobsRes = res.data.data
                    // const JobDerails = []
                    // JobsRes.map((sj) => {
                    //     const apJob = {
                    //         job_id: sj.job_id,
                    //         company_logo_path: sj.company_logo_path,
                    //         Job_title: sj.job_title,
                    //         company: sj.company_long_name,
                    //         jobType: sj.job_type,
                    //         postedAt: "Posted " + convertToTimeSince(sj.job_start_date),
                    //         total_applied: sj.total_applicant,
                    //         numberOfOpenings: sj.vacancy,
                    //         job_salary_range_from: formattedNumber(sj.job_salary_range_from),
                    //         job_salary_range_to: formattedNumber(sj.job_salary_range_to),
                    //         gender: sj.gender === "Female" ? "F" : "M",
                    //         jobDescription: sj.job_description,
                    //         qualification: sj.edu.length ? sj.edu : [],
                    //         responsibilities: sj.resp.length ? sj.resp : [],
                    //         skills: sj.skills.length ? sj.skills : [],
                    //         education: sj.edu.length ? sj.edu : [],
                    //         job_experiance: sj.job_experiance,
                    //         // designation: "Senior level",
                    //         country: sj.country[0].country_name,
                    //         city: sj.city[0].city_name,
                    //         currency: sj.currency_code,
                    //         nationality: sj.nationality.length ? sj.nationality : [],
                    //         isSaved: this.allSavedJobs.length ? Boolean(this.allSavedJobs.filter(item => item.job_id === sj.job_id).length) : false
                    //     }
                    //     JobDerails.push(apJob)
                    // })

                    // this.userJobs = JobDerails
                    //// console.log(this.userJobs)
                    loader.hide()
                })
                .catch((error) => {
                    loader.hide()
                    //console.log(error);
                })
        },

        UpdateAnswer() {
            this.isScoreModal = false
            const userData = this.$store.getters.getLoggedUser
            //console.log('userData', userData.userId);
            const payload = {
                "user_id": userData.userId,
                "job_id": this.job_id,
                "company_id": this.company_id,
                "ans": {
                    "virtualInterview": this.answeredQue
                }
            }
            //// console.log('payload', payload);
            CandidateService.AnswerInterview(payload)
                .then((res) => {
                    console.log('res', res);
                    if (res.data.status)
                        this.$toasted.show(`Answer submitted`, {
                            duration: 6000,
                            type: "success",
                            icon: "check",
                        });
                    setTimeout(() => {

                        this.isApplied = true
                    }, 800)

                })
                .catch((error) => {
                    this.$toasted.show(`Image extension not suppoted`, { duration: 6000 });
                    loader.hide();
                    //console.log("error", error);
                })

        },
        getScore() {
            const userData = this.$store.getters.getLoggedUser
            //console.log('userData', userData.userId);
            const payload = {
                "user_id": userData.userId,
                "job_id": this.job_id,
                "company_id": this.company_id,
                "ans": {
                    "virtualInterview": this.answeredQue
                }
            }
            //// console.log('payload', payload);
            CandidateService.getScore(payload)
                .then((res) => {
                    console.log('res', res);
                    if (res.data.status && res.data.data < 50) {
                        // this.$toasted.show(res.data.data, {
                        //     duration: 6000,
                        //     type: "success",
                        //     icon: "check",
                        // });
                        setTimeout(() => {

                            this.isScoreModal = true
                        }, 800)
                    }
                    else {

                        CandidateService.AnswerInterview(payload)
                            .then((res) => {
                                console.log('res', res);
                                if (res.data.status)
                                    this.$toasted.show(`Answer submitted`, {
                                        duration: 6000,
                                        type: "success",
                                        icon: "check",
                                    });
                                setTimeout(() => {

                                    this.isApplied = true
                                }, 800)

                            })
                            .catch((error) => {
                                this.$toasted.show(`Something went wrong`, { duration: 6000 });
                                loader.hide();
                                //console.log("error", error);
                            })
                    }

                })
                .catch((error) => {
                    this.$toasted.show(`Something went wrong to get score `, { duration: 6000 });
                    loader.hide();
                    //console.log("error", error);
                })

        },
    }

}
</script>

<style>
.zc-card {
    max-width: 37.25rem;
    min-height: fit-content;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.10);
}

.vi-job {
    color: #000;
    font-family: var(--font-family-sans-serif);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 2.0625rem */
}

.q-font {
    font-size: 1.375rem;
    font-weight: 600;
}

.question {
    color: #000;
    font-family: var(--font-family-sans-serif);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 1.6875rem */
}

.option {
    width: fit-content;
    font-family: var(--font-family-sans-serif);
    font-size: clamp(1rem, 3vw, 1.125rem);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0.6875rem 0.9375rem;
    border-radius: 0.625rem;
    background: #EDEDED;
    color: #979797;
}

.answered {
    border-radius: 0.625rem;
    background: #2D68B2;
    color: #FFFFFF;
}


.iv-note {
    color: #2D68B2;
    font-family: var(--font-family-sans-serif);
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    padding-top: 1rem;
    line-height: 200%;
}

@media screen and (max-width:992px) {
    .zc-card {
        max-width: 31.25rem;
        min-height: fit-content;
        /* min-height: 37.625rem; */
        flex-shrink: 0;
        border-radius: 0.625rem;
        background: var(--neutral-white, #FFF);
        box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.10);
    }
}

@media screen and (max-width:568px) {
    .go-back {
        font-size: .75rem;
    }

    #see-more {
        padding: 9px;
    }

    .q-font {
        font-size: 1.125rem;
    }

    .vi-job {
        font-size: 1.125rem;
    }

    .question {
        font-size: 1rem;
    }

    .option {
        font-size: 0.73269rem;
    }
}
</style>